import React from "react";
import nav from "./Css/navi.module.css"

function Banner(){
    return (
    <div className={nav.banner}>
                <h3>the MAGIC SPICE
                <h4>The Leader of the culinary art since 1956</h4>
                </h3>
                
            </div>
    )
}export default Banner;