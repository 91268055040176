import Nav from "./Components/Navi"
import Cart from "./Components/cart components/Cart"
import Footer from "./Components/Footer"

function Usercart(){
    return(
        <div>
            <Nav/>
            <Cart/>
            <Footer/>
        </div>
    )
}export default Usercart;