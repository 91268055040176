import React from "react";
import ProductDetails from "./Components/Details";
import Navi from "./Components/Navi"
import Footer from "./Components/Footer"

function ProductDetail(){
    return(
        <div>
    <Navi/>
    <ProductDetails/>
    <Footer/>
    </div>
    )
}export default ProductDetail