import React from "react";
import Navi from "./Components/Navi";
import Order from "./Components/order components/Order";
import Footer from "./Components/Footer";

function Confirm(){
    return(
        <div>
            <Navi/>
            <Order/>
            <Footer/>
        </div>
    )
}export default Confirm;